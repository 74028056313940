export const shuffle = <T>(array: T[]): T[] => {
  const tmp = [...array];

  let currentIndex = tmp.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [tmp[currentIndex]!, tmp[randomIndex]!] = [
      tmp[randomIndex]!,
      tmp[currentIndex]!,
    ];
  }

  return tmp;
};

export const splitArray = <T>(array: T[], numberOfParts: number) => {
  const result = [];
  const chunkSize = Math.ceil(array.length / numberOfParts);

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);

    result.push(chunk);
  }

  return result;
};

export const pickRandomElementInArray = <T>(array: T[]): T =>
  array[Math.floor(Math.random() * array.length)]!;

export const toArray = <T>(input: T | T[]): T[] => {
  if (input === undefined) return [];

  if (Array.isArray(input)) return input;

  return [input];
};
